import { Link } from "gatsby"
import React from "react"

import Logo from "../img/logo-header.inline.svg"

export default class Navbar extends React.Component {
  state = {
    activeMenu: false
  }
  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu
    })
  }
  render() {
    return (
      <div className="header-container">
        <header>
          <Link to="/" className="logo">
            <Logo width={74} height={40} />
          </Link>
          <a
            className={`menu-button ${this.state.activeMenu ? "active" : ""}`}
            onClick={this.toggleMenu}
          >
            <span />
            <span />
            <span />
          </a>
          <nav className={`${this.state.activeMenu ? "visible" : "hidden"}`}>
            <ul>
              <li>
                <Link to="/">
                  TOP<span>トップ</span>
                </Link>
                <a href=""></a>
              </li>
              <li>
                <Link to="/news">
                  NEWS<span>お知らせ</span>
                </Link>
                <a href=""></a>
              </li>
              <li>
                <Link to="/services">
                  SERVICES<span>サービス</span>
                </Link>
                <a href=""></a>
              </li>
              <li>
                <Link to="/about">
                  ABOUT<span>ネイリストについて</span>
                </Link>
              </li>
              <li>
                <Link to="/blog">
                  BLOG<span>ブログ</span>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  BOOK NOW<span>予約する</span>
                </Link>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    )
  }
}
