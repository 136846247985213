import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import "./all.sass"
import useSiteMetadata from "./SiteMetadata"
import { withPrefix } from "gatsby"

const TemplateWrapper = ({ children }) => {
  const { title, description, keyword } = useSiteMetadata()
  return (
    <div className="wrapper">
      <Helmet>
        <html lang="ja" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keyword} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/favicons/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicons/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicons/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/favicons/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:title" content="💅🏼 1989 Private Nail Salon" />
        <meta
          property="og:description"
          content="渋谷区富ケ谷にあるプライベートネイルサロン「1989」。ゆったり過ごせるプライベートな空間で、お客さまひとりひとりのご要望にしっかりお応えし、「自分らしいネイル」をご提案いたします。"
        />
        <meta
          property="og:type"
          content="website"
          meta
          property="og:url"
          content="https://1989.salon/"
        />
        <meta property="og:site_name" content="1989 private nail salon" />
        <meta property="og:image" content={`${withPrefix("/")}img/fbogp.png`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@nailsalon1989" />
        <meta name="twitter:creator" content="@nailsalon1989" />
      </Helmet>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
