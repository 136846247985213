import React from "react"
import Layout from "../components/Layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <section class="section has-text-centered">
        <h1>NOT FOUND</h1>
        <p>ページが見つかりませんでした</p>
      </section>
    </Layout>
  )
}

export default NotFoundPage
