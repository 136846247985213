import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import BlogRoll from "../../components/BlogRoll"

const BlogIndexPage = ({
  data: {
    site: {
      siteMetadata: { title }
    }
  }
}) => {
  return (
    <Layout>
      <Helmet title={`ブログ | ${title}`} />
      <section className="section">
        <h1 className="has-text-centered">Blog</h1>
        <BlogRoll />
      </section>
    </Layout>
  )
}

export default BlogIndexPage

export const blogIndexPageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
