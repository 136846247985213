import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../img/logo-description.inline.svg"
import mail from "../img/mail.svg"
import { useMediaQuery } from "react-responsive"
import BackgroundImage from "../components/BackgroundImage"

export const IndexPageTemplate = ({ data }) => {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 767 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  return (
    <div className="container">
      <section className="welcome_desc has-text-centered" id="welcome">
        <Desktop>
          <BackgroundImage fluid={data.descktopImage.childImageSharp.fluid} />
        </Desktop>
        <Mobile>
          <BackgroundImage fluid={data.mobileImage.childImageSharp.fluid} />
        </Mobile>
        <div className="overlay">
          <div className="text">
            <p>Welcome to</p>
            <Logo width={240} height={140} />
            <Link to="/contact">
              <img src={mail} />
              BOOK NOW<span className="ja">予約する</span>
            </Link>
          </div>
        </div>
      </section>
      <section className="section about_desc has-text-centered">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2>News</h2>
              いつも1989をご愛顧いただきまして誠にありがとうございます。
              <br />
              緊急事態宣言を受けて臨時休業をしておりましたが、感染予防対策を講じた上で、6月1日より営業を再開いたしました。
              <br />
              皆さまのご来店を心よりお待ちしております。
            </div>
            <div className="section">
              <h2>About 1989 💅</h2>
              <Desktop>
                <p>
                  1989は、速さメインのサロンではなく、リビングの一角で、
                  <br />
                  一人一人のお客様と時間をかけて、ネイルを作り上げていくスタイルです。
                  <br />
                  お客様のイメージに合ったパーツなどを、その日のために購入しに行く事もあります。
                  <br />
                  ゆっくり映画を見ていただいても、お喋りやお悩み相談もokです！
                  <br />
                  ネイルサロンって緊張する...と気負わずに、
                  <br />
                  カフェに立ち寄ったような⁈お友達の家に遊びに来たような気分で⁈
                  <br />
                  リラックスしてお過ごしください。
                  <br />
                  <br />
                  1989は、代々木公園駅から神奈川県の藤沢駅に移転いたしました。
                  <br />
                  江ノ電の始発駅でもあり終着駅でもある藤沢駅。
                  <br />
                  海や自然が豊富にあり、
                  <br />
                  代々木上原・新宿・渋谷・恵比寿・東京・などの都心や、その他様々な駅に一本でアクセスも出来る駅です。
                  <br />
                  移転に伴い、お客様にはご不便、ご迷惑をお掛け致しますがご理解の程宜しくお願い申し上げます。
                  <br />
                  これからもより愛される1989を目指し、
                  <br />
                  感謝の気持ちを込めて皆様をお迎えしてまいりますので、ご来店心よりお待ち致しております。
                  <br />
                  最後までお読み頂きありがとうございました☺️
                </p>
              </Desktop>
              <Mobile>
                <p>
                  1989は、速さメインのサロンではなく、リビングの一角で、一人一人のお客様と時間をかけて、ネイルを作り上げていくスタイルです。
                  お客様のイメージに合ったパーツなどを、その日のために購入しに行く事もあります。
                  ゆっくり映画を見ていただいても、お喋りやお悩み相談もokです！
                  ネイルサロンって緊張する...と気負わずに、カフェに立ち寄ったような⁈お友達の家に遊びに来たような気分で⁈リラックスしてお過ごしください。
                  <br />
                  <br />
                  1989は、代々木公園駅から神奈川県の藤沢駅に移転いたしました。
                  江ノ電の始発駅でもあり終着駅でもある藤沢駅。海や自然が豊富にあり、代々木上原・新宿・渋谷・恵比寿・東京・などの都心や、その他様々な駅に一本でアクセスも出来る駅です。
                  移転に伴い、お客様にはご不便、ご迷惑をお掛け致しますがご理解の程宜しくお願い申し上げます。
                  これからもより愛される１９８９を目指し、感謝の気持ちを込めて皆様をお迎えしてまいりますので、ご来店心よりお待ち致しております。
                  最後までお読み頂きありがとうございました☺️
                </p>
              </Mobile>
            </div>
            <div className="section">
              <h2>Location 📍</h2>
              <address>〒251-0052 神奈川県藤沢市藤沢</address>
              <span>
                ホームサロンのため、住所の詳細は予約確定後にお知らせします
              </span>
            </div>
            <div className="section">
              <h2>Access 🚂</h2>
              <div className="transportation">
                <h3>電車</h3>
                <p>藤沢駅北口より徒歩7分</p>
              </div>
            </div>
            <div className="section">
              <h2>Opening Hours ⌛</h2>
              24 hours
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  data: PropTypes.shape({
    descktopImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.oneOfType([
          PropTypes.shape({}),
          PropTypes.arrayOf(PropTypes.shape({}))
        ])
      })
    }),
    mobileImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.oneOfType([
          PropTypes.shape({}),
          PropTypes.arrayOf(PropTypes.shape({}))
        ])
      })
    })
  })
}

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <IndexPageTemplate data={data} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    descktopImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.oneOfType([
          PropTypes.shape({}),
          PropTypes.arrayOf(PropTypes.shape({}))
        ])
      })
    }),
    mobileImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.oneOfType([
          PropTypes.shape({}),
          PropTypes.arrayOf(PropTypes.shape({}))
        ])
      })
    })
  })
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    descktopImage: file(relativePath: { eq: "key-visual.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobileImage: file(relativePath: { eq: "key-visual.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
