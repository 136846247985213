import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import PreviewCompatibleImage from "./PreviewCompatibleImage"

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="container" style={{ maxWidth: 640 }}>
        {posts &&
          posts.map(({ node: post }) => {
            return (
              <div key={post.id}>
                <article className={`blog-list-item box`}>
                  <header>
                    {post.frontmatter.featuredimage ? (
                      <Link to={post.fields.slug}>
                        <div className="featured-thumbnail">
                          <PreviewCompatibleImage
                            imageInfo={{
                              image: post.frontmatter.featuredimage,
                              alt: `featured image thumbnail for post ${post.title}`
                            }}
                          />
                        </div>
                      </Link>
                    ) : null}
                    <p className="post-meta">
                      <Link
                        className="is-size-4 has-text-weight-bold"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>
                      <span className="is-size-6 is-block">
                        {post.frontmatter.date}
                      </span>
                    </p>
                  </header>
                  <p className="post-excerpt">{post.excerpt}</p>
                  <Link className="is-size-6" to={post.fields.slug}>
                    Read more…
                  </Link>
                </article>
              </div>
            )
          })}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
}

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                excerpt(truncate: true)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredimage {
                    childImageSharp {
                      fluid(maxWidth: 640, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => {
        return <BlogRoll data={data} count={count} />
      }}
    />
  )
}
