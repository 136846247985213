module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://1989.salon"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-113163735-2"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    }]
