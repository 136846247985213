import React from "react"
import instagram from "../img/social/instagram.svg"
import lineat from "../img/social/lineat.svg"
import twitter from "../img/social/twitter.svg"
import pinterest from "../img/social/pinterest.svg"
import base from "../img/base.svg"

const Footer = class extends React.Component {
  render() {
    return (
      <footer>
        <div className="footer-wrapper">
          <div className="sns-contents">
            <a
              href="https://www.instagram.com/1989_artsy_nails/"
              target="_blank"
            >
              <i>
                <img alt="instagram" src={instagram} width="40" height="40" />
              </i>
              <span>Instagram</span>
            </a>
            <a href="https://twitter.com/nailsalon1989" target="_blank">
              <i width="40" height="40">
                <img alt="twitter" src={twitter} width="40" height="40" />
              </i>
              <span>Twitter</span>
            </a>
            <a href="https://www.pinterest.jp/1989mei/" target="_blank">
              <i>
                <img alt="pinterest" src={pinterest} width="40" height="40" />
              </i>
              <span>Pinterest</span>
            </a>
            <a href="https://line.me/R/ti/p/%40vcs6280q" target="_blank">
              <i>
                <img alt="line@" src={lineat} width="40" height="40" />
              </i>
              <span>LINE@</span>
            </a>
            <a href="https://1989mei.thebase.in/" target="_blank">
              <i>
                <img alt="BASE" src={base} width="40" height="40" />
              </i>
              <span>BASE</span>
            </a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
