import Content, { HTMLContent } from "../components/Content"

import Helmet from "react-helmet"
import Layout from "../components/Layout"
import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"

export const ContactPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section has-text-centered">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1>{title}</h1>
            <PageContent content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
}

const ContactPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { title }
    },
    markdownRemark: post
  } = data

  return (
    <Layout>
      <Helmet title={`ご予約について | ${title}`} />
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ContactPage

export const aboutPageQuery = graphql`
  query ContactPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
